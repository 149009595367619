<template lang="pug">
section.homeIntro
  .homeIntro-background: VideoBackground.homeIntro-video(
    :src='require("@/assets/video.mp4")',
    :poster='require("@/assets/images/intro.jpg")',
    :sources='[{ src: require("@/assets/video-mobile.mp4"), res: 768, autoplay: true, poster: require("@/assets/images/introm.jpg") }]'
  )
    .homeIntro-filter
  .homeIntro-container 
    Social.homeIntro-social
    h1.homeIntro-title Real estate in the<br> Caribbean
    .homeIntro-div(style='')
</template>

<script>
  export default {
    components: {
      VideoBackground: () => import('vue-responsive-video-background-player'),
      Social: () => import('@/components/Social'),
    },
  };
</script>

<style lang="scss">
  .homeIntro {
    display: contents;
    &-background {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);

      overflow: hidden;

      video {
        filter: contrast(1.2);
      }
      //  Plugin slot class
    }
    &-video {
      height: 100%;
      position: fixed !important;
      top: 0;
      z-index: -2;
    }
    &-title {
      position: relative;
      text-transform: uppercase;
      font-size: 30px;
      font-family: 'Proto Grotesk Web';
      text-align: center;
      @include middle {
        font-size: 28px;
      }
      @include tablet {
        font-size: 24px;
        line-height: 29px;
      }
      br {
        display: none;
        @include tablet {
          display: block;
        }
      }
    }

    &-filter {
      // background: linear-gradient(
      //   180deg,
      //   rgba(0, 0, 0, 0.6) 9.25%,
      //   rgba(0, 0, 0, 0) 67.69%
      // );
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      @include tablet {
        position: absolute;
        z-index: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
    &-social {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include gap(40px, col);
      @include middle {
        visibility: hidden;
      }
      @include tablet {
        display: none;
      }
    }
    &-container {
      display: flex;
      align-items: flex-end;
      padding-bottom: 55px;
      position: relative;
      color: white;
      justify-content: space-between;
      @include tablet {
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;
      }
    }
    &-div {
      visibility: hidden;
      width: 30px;
      @include tablet {
        display: none;
      }
    }
  }
</style>
